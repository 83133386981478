import { gql } from '@apollo/client';

export const GET_WELDERS_BY_WELD = gql`
  query GetWeldersByWeld($weldId: Int!) {
    welders_by_weld(weld_id: $weldId) {
      id
      active
      last_name
      name
      user {
        id
      }
      uuid
    }
  }
`;
