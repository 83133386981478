import { default as React, useEffect, useMemo, useState } from "react";
import { useLazyQuery, useQuery } from '@apollo/client';
import ClearIcon from "@mui/icons-material/Clear";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight"; // Upewnij się, że to jest poprawny import
import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, Container, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import LinearProgress from "@mui/material/LinearProgress";
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Paper from "@mui/material/Paper";
import Stack from '@mui/material/Stack';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { GET_WELDS } from "../graphql/getWelds";
import { GET_WELDS_FILTERS } from '../graphql/getWeldsFilters';

const removeFromHash = (hash, keyToRemove) => {
  return Object.entries(hash)
    .filter(([key]) => key !== keyToRemove)
    .reduce((obj, [key, value]) => ({
      ...obj,
      [key]: value
    }), {});
};

const clearHash = (params) => {
  return Object.entries(params)
    .filter(([_, value]) => value !== null && value !== '')
    .reduce((acc, [key, value]) => ({
      ...acc,
      [key]: value
    }), {})
}

export default function Welds() {
  const [projectId] = useState(Number(useParams().projectId));
  const [searchParams, setSearchParams] = useSearchParams();
  const [applyFilters] = useState(false);

  const [urlParams, setUrlParams] = useState({
    no: searchParams.get('no') || '',
    weld_type: searchParams.get('weld_type') || '',
    length: searchParams.get('length') || '',
    material_1: searchParams.get('material_1') || '',
    material_2: searchParams.get('material_2') || '',
    wps: searchParams.get('wps') || '',
    status: searchParams.get('status') || '',
    page: parseInt(searchParams.get('page') || '1')
  });

  const [tempFilters, setTempFilters] = useState(urlParams);


  const { data, loading, error, refetch } = useQuery(GET_WELDS, {
    variables: {
      projectId: projectId,
      page: urlParams.page,
      size: 10,
      filters: clearHash(removeFromHash(urlParams, 'page')),
    },
  });

  const [optionsMaterial1, setOptionsMaterial1] = useState([]);
  const [inputTextMaterial1, setInputTextMaterial1] = useState("");
  const [optionsMaterial2, setOptionsMaterial2] = useState([]);
  const [inputTextMaterial2, setInputTextMaterial2] = useState("");
  const [optionsWPS, setOptionsWPS] = useState([]);
  const [inputTextWPS, setInputTextWPS] = useState("");
  const [optionsWeldType, setOptionsWeldType] = useState([]);
  const [inputTextWeldType, setInputTextWeldType] = useState("");
  const [optionsStatus, setOptionsStatus] = useState([{ id: 'new', label: 'New' }, { id: 'ready', label: 'Ready' }, { id: 'in_progress', label: 'In progress' }, { id: 'ok', label: 'Ok' }, { id: 'failed', label: 'Failed' }, { id: 'inconsistent', label: 'Inconsistent' }]);
  const [inputTextStatus, setInputTextStatus] = useState("");
  const [getWledFilters] = useLazyQuery(GET_WELDS_FILTERS);

  useEffect(() => {
    setOptionsMaterial1([])
    setOptionsMaterial2([])
    setOptionsWPS([])
    setOptionsWeldType([])
    setOptionsStatus([{ id: 'new', label: 'New' }, { id: 'ready', label: 'Ready' }, { id: 'in_progress', label: 'In progress' }, { id: 'ok', label: 'Ok' }, { id: 'failed', label: 'Failed' }, { id: 'inconsistent', label: 'Inconsistent' }])

    getWledFilters({
      variables: {
        base_material_1: inputTextMaterial1,
        base_material_2: inputTextMaterial2,
        size: 10,
        project_id: projectId
      }
    }).then((response) => {
      setOptionsMaterial1(response.data?.base_materials_1.map(base_material => ({ id: base_material.id, label: base_material.designation_name })))
      setOptionsMaterial2(response.data?.base_materials_2.map(base_material => ({ id: base_material.id, label: base_material.designation_name })))
      setOptionsWPS(response.data?.wps_list?.list.map(wps => ({ id: wps.id, label: wps.no })))
      setOptionsWeldType(getWeldType(response.data?.type_of_welds, response.data?.joint_types))
      setOptionsStatus(response.data?.available_weld_statuses?.map(status => ({ id: status.code, label: status.name })))
    })
  }, [projectId, getWledFilters, inputTextMaterial1, inputTextMaterial2, inputTextWPS, inputTextWeldType, inputTextStatus, setOptionsMaterial1, setOptionsMaterial2, setOptionsWPS, setOptionsWeldType, setOptionsStatus])

  const status_default_color = '#D5D5D5'

  const statuses_colors = { 'new': status_default_color, 'in_progress': '#FFA500', 'ready': '#398E3C', 'faild': '#FC0101', 'ok': '#359843', 'inconsistent': '#532D8E' }

  const weld_typeSelected = useMemo(
    () => optionsWeldType.find((weld_type) => weld_type.id === urlParams.weld_type),
    [optionsWeldType, urlParams.weld_type]
  )

  const material_1Selected = useMemo(
    () => optionsMaterial1.find((material_1) => material_1.id === urlParams.material_1),
    [optionsMaterial1, urlParams.material_1]
  )

  const material_2Selected = useMemo(
    () => optionsMaterial2.find((material_2) => material_2.id === urlParams.material_2),
    [optionsMaterial2, urlParams.material_2]
  )

  const wpsSelected = useMemo(
    () => optionsWPS.find((wps) => wps.id === urlParams.wps),
    [optionsWPS, urlParams.wps]
  )

  const statusSelected = useMemo(
    () => optionsStatus.find((status) => status.id === urlParams.status),
    [optionsStatus, urlParams.status]
  )

  const transformStatuses = (statuses) => {
    let statuses_hash = {}
    statuses.forEach((status) => {
      statuses_hash[status.id] = {
        label: status.label,
        color: statuses_colors[status.id] || status_default_color,
      }
    })
    return statuses_hash
  }

  const statuses = useMemo(
    () => transformStatuses(optionsStatus),
    [optionsStatus]
  );

  const getWeldType = (type_of_welds, joint_types) => {
    let weld_types = []
    type_of_welds.forEach((type_of_weld) => {
      if (type_of_weld.name === "BW" || type_of_weld.name === "T-joint") {
        weld_types = [...weld_types, { id: `${type_of_weld.id}_`, label: type_of_weld.name }]
        joint_types.forEach((joint_type) => {
          weld_types = [...weld_types, { id: `${type_of_weld.id}_${joint_type.id}`, label: `${type_of_weld.name}-${joint_type.name}` }]
        })
      } else {
        weld_types = [...weld_types, { id: `${type_of_weld.id}_`, label: type_of_weld.name }]
      }
    })
    return weld_types
  }

  const handleFilterChange = (key, value) => {
    setTempFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  const handlePageChange = (event, value) => {
    setSearchParams((prevFilters) => ({
      ...clearHash(urlParams),
      page: value,
    }));
    setUrlParams((prevFilters) => ({
      ...prevFilters,
      page: value,
    }));
  };

  const handleApplyFilters = () => {
    setSearchParams(
      Object.entries(tempFilters)
        .filter(([_, value]) => value !== null && value !== '')
        .reduce((acc, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {})
    );

    setUrlParams(tempFilters);
    refetch({
      page: urlParams.page,
      projectId: projectId,
      filters: clearHash(removeFromHash(urlParams, 'page'))
    });
  };

  const resetFilters = async () => {
    setSearchParams({});
    window.location.reload();
  };

  if (loading && !applyFilters)
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    );
  if (error) return <Alert severity='error'>{error.message}</Alert>;

  const welds = data?.welds?.list || [];
  const metadata = data?.welds?.metadata || [];

  const Item = styled(Paper)(({ theme, statusColor }) => ({
    backgroundColor: statusColor,
    textAlign: "center",
    padding: theme.spacing(1),
  }));

  const statusItem = (weld) => {
    return (
      <Item
        variant='contained'
        sx={{
          backgroundColor: statuses[weld.status]['color'],
          fontSize: "12px",
        }}
      >
        {statuses[weld.status]['label']}
      </Item>
    );
  };

  const handleOnClick = (weld_id) => {
    window.location.href = `/weld/${projectId}/${weld_id}`;
  };

  const actionButton = (weld) => {
    return ["ready", "in_progress"].includes(weld.status) && weld.welding_progress < 100 ? (
      <Button
        endIcon={<KeyboardDoubleArrowRightIcon />}
        onClick={() => {
          handleOnClick(weld.id);
        }}
        variant='outlined'
        size='small'
        sx={{ marginRight: "0px", float: "right" }}
      >
        Weld
      </Button>
    ) : null;
  };

  return (
    <Container sx={{ paddingTop: 4 }}>
      <TableContainer component={Paper} sx={{ maxHeight: 1000 }}>
        <Table>
          <TableHead
            sx={{
              position: "sticky",
              top: 0,
              backgroundColor: "white",
              mt: 4,
              zIndex: 2,
            }}
          >
            <TableRow>
              <TableCell sx={{ width: "300px", padding: "8px" }}>
                <TextField
                  label='No'
                  defaultValue={urlParams.no}
                  variant='outlined'
                  size='small'
                  fullWidth
                  onChange={(e) => handleFilterChange("no", e.target.value)}
                  type="string"
                  sx={{
                    // Remove arrows in Chrome, Safari, Edge, Opera
                    '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
                      '-webkit-appearance': 'none',
                      margin: 0
                    },
                    // Remove arrows in Firefox
                    '& input[type=number]': {
                      '-moz-appearance': 'textfield'
                    }
                  }}
                />
              </TableCell>
              <TableCell sx={{ width: "100px", padding: "8px" }}>
                <Autocomplete
                  value={weld_typeSelected ? weld_typeSelected["label"] : ""}
                  onChange={(event, value) =>
                    handleFilterChange("weld_type", value?.id || "")
                  }
                  options={optionsWeldType}
                  filterOptions={(optionsWeldType) => optionsWeldType}
                  onInputChange={(event, newValue) => {
                    setInputTextWeldType(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Weld type'
                      variant='outlined'
                      size='small'
                      fullWidth
                    />
                  )}
                  sx={{ width: 150, fontSize: 10, p: 0 }}
                />
              </TableCell >
              <TableCell sx={{ width: "300px", padding: "8px" }}>
                <TextField
                  type="number"
                  label='Length'
                  defaultValue={urlParams.length}
                  variant='outlined'
                  size='small'
                  fullWidth
                  onChange={(e) => handleFilterChange("length", e.target.value)}
                  sx={{
                    // Remove arrows in Chrome, Safari, Edge, Opera
                    '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
                      '-webkit-appearance': 'none',
                      margin: 0
                    },
                    // Remove arrows in Firefox
                    '& input[type=number]': {
                      '-moz-appearance': 'textfield'
                    }
                  }}
                />
              </TableCell>
              <TableCell sx={{ width: "200px", padding: "8px" }}>
                <Autocomplete
                  value={material_1Selected ? material_1Selected["label"] : ""}
                  onChange={(event, value) =>
                    handleFilterChange("material_1", value?.id || "")
                  }
                  options={optionsMaterial1}
                  filterOptions={(optionsMaterial1) => optionsMaterial1}
                  onInputChange={(event, newValue) => {
                    setInputTextMaterial1(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Material 1'
                      variant='outlined'
                      size='small'
                      fullWidth
                    />
                  )}
                  sx={{ width: 150, fontSize: 10, p: 0 }}
                />
              </TableCell>
              <TableCell sx={{ width: "200px", padding: "8px" }}>
                <Autocomplete
                  value={material_2Selected ? material_2Selected["label"] : ""}
                  onChange={(event, value) =>
                    handleFilterChange("material_2", value?.id || "")
                  }
                  options={optionsMaterial2}
                  filterOptions={(optionsMaterial2) => optionsMaterial2}
                  onInputChange={(event, newValue) => {
                    setInputTextMaterial2(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Material 2'
                      variant='outlined'
                      size='small'
                      value="{urlParams.material_2}"

                      fullWidth
                    />
                  )}
                  sx={{ width: 150, fontSize: 10, p: 0 }}
                />
              </TableCell>
              <TableCell sx={{ width: "200px", padding: "8px" }}>
                <Autocomplete
                  value={wpsSelected ? wpsSelected["label"] : ""}
                  onChange={(event, value) =>
                    handleFilterChange("wps", value?.id || "")
                  }
                  options={optionsWPS}
                  filterOptions={(optionsWPS) => optionsWPS}
                  onInputChange={(event, newValue) => {
                    setInputTextWPS(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='WPS'
                      variant='outlined'
                      size='small'
                      fullWidth
                    />
                  )}
                  sx={{ width: 150, fontSize: 10, p: 0 }}
                />
              </TableCell>
              <TableCell sx={{ width: "200px", padding: "8px" }}>
                <Autocomplete
                  value={statusSelected ? statusSelected["label"] : ""}
                  onChange={(event, value) =>
                    handleFilterChange("status", value?.id || "")
                  }
                  options={optionsStatus}
                  filterOptions={(optionsStatus) => optionsStatus}
                  onInputChange={(event, newValue) => {
                    setInputTextStatus(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Status'
                      variant='outlined'
                      size='small' s
                      fullWidth
                    />
                  )}
                  sx={{ width: 150, fontSize: 10, p: 0 }}
                />
              </TableCell>

              <TableCell sx={{ display: "flex" }}>
                <Button
                  endIcon={<SearchIcon sx={{ marginRight: "10px" }} />}
                  sx={{ marginRight: "4px", float: "center", height: "40px" }}
                  variant='contained'
                  color='primary'
                  onClick={handleApplyFilters}
                />
                <Button
                  endIcon={<ClearIcon sx={{ marginRight: "10px" }} />}
                  sx={{
                    backgroundColor: "#F70000",
                    marginRight: "0px",
                    float: "center",
                    height: "40px",
                  }}
                  variant='contained'
                  onClick={resetFilters}
                />
              </TableCell>
            </TableRow >
            <TableRow>
              <TableCell>No</TableCell>
              <TableCell>Weld Type</TableCell>
              <TableCell>Length</TableCell>
              <TableCell>Material 1</TableCell>
              <TableCell>Material 2</TableCell>
              <TableCell>WPS</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead >
          <TableBody>
            {welds.map((weld) => (
              <TableRow key={weld.id}>
                <TableCell>{weld.no}</TableCell>
                <TableCell>{weld.weld_type?.name || "-"}</TableCell>
                <TableCell>{weld.length}</TableCell>
                <TableCell>{weld.material_1?.designation_name || "-"}</TableCell>
                <TableCell>{weld.material_2?.designation_name || "-"}</TableCell>
                <TableCell>{weld.wps?.no || "-"}</TableCell>
                <TableCell>{statusItem(weld)}</TableCell>
                <TableCell>{actionButton(weld)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table >
      </TableContainer >

      <Stack spacing={2}>
        {metadata.total_pages > 0 && (
          <Pagination
            sx={{ display: "flex", justifyContent: "center", padding: "12px" }}
            onChange={handlePageChange}
            page={urlParams.page}
            count={metadata.total_pages}
            renderItem={(item) => (
              <PaginationItem
                {...item}
              />
            )}
          />
        )}
        {parseInt(metadata.total_pages) === 0 && (
          <Box>
            <Typography
              variant="h5"
              sx={{
                textAlign: "center",
                padding: "12px",
                background: "#D5D5D5",
                color: "#ffffff",
                fontWeight: "bold",
              }}
            >
              No results found
            </Typography>
          </Box>
        )}
      </Stack>
    </Container >
  );
}
